import React from 'react';
import Layout from "../components/layout"
import {Link, graphql, useStaticQuery} from "gatsby"
import blogStyles from "./blog.module.scss"
import SEO from "../components/seo.js"

const BlogPage = () => {
    const posts=useStaticQuery(graphql`
    query{
        allMarkdownRemark{
          edges{
            node{
              frontmatter{
                title
                date
              }
              fields{
                slug
              }
            }
          }
        }
      }
    
    `)
    console.log(posts)
    return ( 
        <Layout>
          <SEO title="Anonycoder Blog Page"/>
            <h1>Posts</h1>
            <ol className={blogStyles.posts}>
                {posts.allMarkdownRemark.edges.map((post)=>{
                    return(
                        <li className={blogStyles.post}>
                          <Link to={`/blog/${post.node.fields.slug}`}>
                            <h2>{post.node.frontmatter.title}</h2>
                            <p>{post.node.frontmatter.date}</p>
                            </Link>
                        </li>
                    )
})}
            </ol>
        </Layout>


     );
}
 
export default BlogPage;